<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.consignment_return_temporary_storage") }}
    </template>
    <template #contentBody="wrap">
      <a-row :gutter="[16, 16]">
        <a-col>
          <a-input
            style="width: 250px"
            v-model:value="searchState.packageNo"
            :placeholder="$t('warehouse.recognizable_barcode')"
            allow-clear
          ></a-input>
        </a-col>
        <a-col>
          <a-select
            style="width: 250px"
            v-model:value="searchState.warehouseId"
            :loading="warehouseLoading"
            :placeholder="
              $t('warehouse.returns_are_transferred_to_the_warehouse')
            "
            allow-clear
          >
            <a-select-option
              v-for="(item, index) in warehouseList"
              :key="index"
              :value="item.id"
              :title="`${item.warehouseNo}(${item.warehouseName})`"
              :search-key="item.warehouseNo + item.warehouseName"
            >{{ item.warehouseNo }}({{ item.warehouseName }})</a-select-option>
          </a-select>
        </a-col>
        <a-col>
          <a-range-picker
            style="width: 250px"
            format="YYYY-MM-DD"
            @change="handleConfirmDate"
          />
        </a-col>
        <a-col>
          <a-button
            type="primary"
            :loading="tableData.loading"
            @click="handleSearch"
            >{{ $t("common.search") }}</a-button
          >
        </a-col>
      </a-row>
      <a-table
        class="mt-3"
        :columns="columns"
        :data-source="tableData.tableList"
        :scroll="{ x: 500, y: wrap.contentHeight - 120 }"
        :pagination="false"
        :rowKey="(record) => record.id"
        :loading="tableData.loading"
        size="small"
      >
        <template #packageTitle>
          <div>
            {{ $t("warehouse.return_the_parcel") }}({{
              $t("warehouse.recognizable_barcode")
            }})
          </div>
          <div>{{ $t("warehouse.additional_identification_information") }}</div>
        </template>
        <template #temporaryDaysTitle>
          <span class="pr-1">{{ $t('warehouse.temporary_storage_time') }}</span>
          <a-popover>
            <template #content>
              <div style="min-width: 250px; max-width: 350px;">
                <div>{{ $t('warehouse.no_return_temporary_storage_fee_within_p0_days', [configInfo.freeRtsDays]) }}</div>
                <div>{{ $t('warehouse.return_temporary_storage_fee_will_be_calculated_after_p0_days', [configInfo.freeRtsDays]) }}</div>
                <div>{{ $t('warehouse.return_information_that_does_not_match_for_more_than_p0_days_can_be_destroyed', [configInfo.destroyRtsTemporaryDays]) }}</div>
              </div>
            </template>
            <QuestionCircleOutlined />
          </a-popover>
        </template>
        <template #packageNo="{ record }">
          <div>{{ record.packageNo || '-' }}</div>
          <div v-if="record.additionalIdentify">{{ record.additionalIdentify }}</div>
          <div v-else>-</div>
        </template>
        <template #packageImgs="{ record }">
          <a-row v-if="record.packageImgs.length > 0" :gutter="[8, 8]" :wrap="true">
            <a-col v-for="(item, index) in record.packageImgs" :key="index">
              <CFileDisplay :fileUrl="item" :ossHeight="1200" :ossWidth="1200"></CFileDisplay>
            </a-col>
          </a-row>
          <span v-else>-</span>
        </template>
        <template #creationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.creationTime) }}
        </template>
        <template #rtsStatus="{ record }">
          <a-typography-text type="success" v-if="record.rtsStatus === rtsStatusEnum.completed">
            <small>{{ $t('warehouse.return_operation_completed') }}</small>
          </a-typography-text>
          <a-typography-text type="warning" v-else-if="record.rtsStatus === rtsStatusEnum.matched">
            <small>{{ $t('warehouse.matched_return_plan') }}</small>
          </a-typography-text>
          <a-typography-text type="danger"  v-else-if="record.rtsStatus === rtsStatusEnum.noMatch">
            <small>{{ $t('warehouse.no_matching_return_plan') }}</small>
          </a-typography-text>
          <a-typography-text type="secondary" v-else-if="record.rtsStatus === rtsStatusEnum.cancel">
            <small>{{ $t($enumLangkey('rtsStatus', record.rtsStatus)) }}</small>
          </a-typography-text>
          <a-typography-text type="secondary" v-else>
            <small v-if="record.destroyedType">{{ $t($enumLangkey('rtsDestroyedType', record.destroyedType)) }}</small>
            <small v-else>-</small>
          </a-typography-text>
        </template>
        <template #temporaryDays="{ record }">
          <template v-if="record.temporaryDays">
            <span>{{ record.temporaryDays }} {{ $t('common.days') }}</span>
            <span v-if="record.rtsStatus === rtsStatusEnum.destroyed && record.destroyedType === rtsDestroyedTypeEnum.warehouse">({{ $t('warehouse.can_be_automatically_destroyed') }})</span>
          </template>
          <span v-else>-</span>
        </template>
        <template #temporaryAmount="{ record }">
          <span v-if="record.temporaryAmount">{{ $filters.formatCurrencyAmount(record.currencySymbol, record.temporaryAmount, false) }}</span>
          <span v-else>-</span>
        </template>
        <template #destroyAmount="{ record }">
          <span v-if="record.destroyAmount">{{ $filters.formatCurrencyAmount(record.currencySymbol, record.destroyAmount, false) }}</span>
          <span v-else>-</span>
        </template>
        <template #channelReturnPlanNo="{ record }">
          {{ record.channelReturnPlanNo || '-' }}
        </template>
        <template #lastModificationTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
        </template>
        <template #operate="{ record }">
          <a-dropdown v-if="record.rtsStatus === rtsStatusEnum.noMatch">
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1" :record="record">{{
                  $t("warehouse.create_channel_return_plan")
                }}</a-menu-item>
                <a-menu-item key="2" :record="record">{{
                  $t("warehouse.destruction")
                }}</a-menu-item>
              </a-menu>
            </template>
            <a-button>
              {{ $t("common.operation") }}
              <DownOutlined />
            </a-button>
          </a-dropdown>
        </template>
      </a-table>
    </template>
    <template #contentFooter>
      <CPager
        class="text-center"
        :page-data="tableData.pageData"
        @handlePage="handlePage"
      ></CPager>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  Row,
  Select,
  Typography,
  Input,
  Modal,
  Table,
  message,
  Popover
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CFileDisplay from "@/views/components/CFileDisplay.vue";
import { currentTimeToUtc } from "@/utils/general";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { warehouseType as warehouseTypeEnum, rtsStatus as rtsStatusEnum, rtsDestroyedType as rtsDestroyedTypeEnum } from "@/enum/enum.json";
import { getWarehouses } from "@/api/modules/common/index.js";
import { getUserReturnTemporaryStorageListPaged, destroyReturnTemporaryStorage, getRtsConfig } from "@/api/modules/consignment/index.js";

export default defineComponent({
  name: "consignment_return_temporary_storage",
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARangePicker: DatePicker.RangePicker,
    AButton: Button,
    ATable: Table,
    ATypographyText: Typography.Text,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    APopover: Popover,
    Content,
    CPager,
    CFileDisplay,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();

    const state = reactive({
      searchState: {
        packageNo: null,
        warehouseId: null,
        creationBeginTime: null,
        creationEndTime: null,
      },
      searchStateCache: {},
      warehouseLoading: false,
      warehouseList: [],
      configInfo: {
        destroyRtsTemporaryDays: 0,
        freeRtsDays: 0,
      },
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        width: 150,
        slots: {
          title: "packageTitle",
          customRender: "packageNo",
        },
      },
      {
        title: () => vueI18n.t('warehouse.picture'),
        width: 150,
        slots: {
          customRender: 'packageImgs',
        }
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.receiving_time"),
        slots: {
          customRender: "creationTime",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "rtsStatus",
        },
      },
      {
        width: 150,
        slots: {
          title: "temporaryDaysTitle",
          customRender: "temporaryDays",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.temporary_storage_fee"),
        slots: {
          customRender: "temporaryAmount",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.destruction_fee"),
        slots: {
          customRender: "destroyAmount",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.return_plan_no"),
        slots: {
          customRender: "channelReturnPlanNo",
        },
      },
      {
        dataIndex: "warehouseNo",
        width: 120,
        title: () => vueI18n.t("warehouse.return_warehouse_code"),
      },
      {
        width: 150,
        title: () => vueI18n.t("warehouse.update_time"),
        slots: {
          customRender: "lastModificationTime",
        },
      },
      {
        width: 150,
        title: () => vueI18n.t("common.operation"),
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handleConfirmDate = (e) => {
      let creationBeginTime = null;
      let creationEndTime = null;
      if (Array.isArray(e) && e.length === 2) {
        creationBeginTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00")
        );
        creationEndTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.creationBeginTime = creationBeginTime;
      state.searchState.creationEndTime = creationEndTime;
    };

    const handleDestruction = (id) => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("warehouse.whether_to_confirm_the_destruction"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return destroyReturnTemporaryStorage({ id }).then(() => {
            message.success(vueI18n.t("common.succeed"));
            getPageList();
          });
        },
      });
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      switch (key) {
        case '1':
          // 创建渠道退货计划
          router.push('/consignment/returnchannel/create?id=' + record.id);
          break;
        case '2':
          // 销毁
          handleDestruction(record.id);
          break;
        default:
          break;
      }
    };

    const getPageList = () => {
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      state.tableData.loading = true;
      getUserReturnTemporaryStorageListPaged(data)
        .then(({ result }) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const getWarehouseList = () => {
      state.warehouseLoading = true;
      const data = {
        isActive: true,
        warehouseType: warehouseTypeEnum.consignmentWarehouse,
        isLinkManIncludeShopNo: true,
      };
      getWarehouses(data)
        .then(({ result }) => {
          if (Array.isArray(result)) {
            state.warehouseList = result;
          } else {
            state.warehouseList = [];
          }
        })
        .catch(() => {})
        .finally(() => {
          state.warehouseLoading = false;
        });
    };

    const handleGetRtsConfig = () => {
      getRtsConfig().then(({ result }) => {
        for (const key in result) {
          if (Object.hasOwnProperty.call(state.configInfo, key)) {
            state.configInfo[key] = result[key]
          }
        }
      }).catch(() => {})
    }

    onMounted(() => {
      getWarehouseList();
      handleGetRtsConfig();
      handleInitPage();
    });

    return {
      ...toRefs(state),
      rtsStatusEnum,
      rtsDestroyedTypeEnum,
      columns,
      handleConfirmDate,
      handleDestruction,
      handleMenuClick,
      handleSearch,
      handlePage,
    };
  },
});
</script>

<style scoped></style>
